.newNaujienaBtn {
  background-color: var(--main-background);
  padding: 10px 30px;
  border-radius: 20px;
  border: none;
  color: black;
}

.newsBtnCont {
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 0;
}

.kablBtnCont {
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 0;
}
