.sectionName {
  letter-spacing: 0.15px;
  margin-left: 5px;
}

.section {
  flex: 1;
  height: 1.69rem;
  display: flex;
  align-items: center;
}

.sectionCont {
  display: flex;
}

.modalContVidus {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  gap: var(--gap-md);
  display: flex;
}
.modalContVidus2 {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  gap: 0;
  display: flex;
}

.gridColumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.gridColumn2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.modalCont {
  padding: 20px 0;
  position: relative;
  width: 100%;
  font-size: 1.13rem;

}

.summaryCont {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  margin: 5px 0;
}

.summaryTypography {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  letter-spacing: 0.15px;
  line-height: 150%;
}

.midTxt {
  width: 10rem;
}

.justifyStart {
  justify-content: flex-start;
}

.alignEnd {
  align-items: end;
}

.smallBtnTxt {
  font-size: 12px;
}

.photos{
  flex-wrap: nowrap;
  overflow-x: auto;
  flex-direction: row;
}



@media screen and (max-width: 767px) {
  .gridColumn {
    grid-template-columns: 1fr;
  }
  .gridColumn2 {
    grid-template-columns: 1fr;
    grid-gap:0;
  }

  .midTxt {
    width: unset;
  }
}