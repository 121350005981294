@media screen and (min-width: 767px) {
  .navContainerMob {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {

  .modal {
    height: 86vh !important;
  }

  .modal3 {
    width: 80% !important;
  }

  .width100Mob {
    width: 100% !important;
  }

  .modal2 {
    min-width: unset !important;
    width: 100%;
    height: 86vh !important;

  }

  .modalTexts {
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: column !important;
  }

  .btn {
    width: unset !important;
  }

  .modal .btn-row {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    align-items: center;
    width: 100%;
  }

  .newsTableBtnCont {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
  }
  .deleteImgNews img{
    width: 22%;
  }

  .deleteImgNews {
    width: unset;
  }

  .naujiena {
    display: flex;
    /* align-items: center; */
    width: 80%;
    /* justify-content: space-around; */
    /* background: antiquewhite; */
    margin: 30px 0px;
    cursor: pointer;
    flex-direction: column;
  }

  .ham {
    display: flex;
    position: absolute;
    left: 30px;
  }

  .navContainerMob {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: var(--main-background);
    position: fixed;
  }

  .navContainer {
    display: none;
  }

  .container {
    margin: 100px 0 0 0;
  }

  .containerNews {
    margin: 40px 0 0 0;
  }

  .mobileNavigationList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 60px;
    font-size: large;
    align-items: center;
  }

  .navLogo {
    /* margin: 20px; */
    width: 100%;
    display: flex;
    height: 40px;
    justify-content: center;
  }

  .navLogo img {
    object-fit: scale-down;
    height: 100%;
  }

  .mobileClose img {
    width: 18px;
    /* margin: 30px; */
    position: absolute;
    left: 40px;
    margin-top: 50px;
    font-weight: 200;
    margin-top: 3;
  }

  .archyvas {
    margin-top: -10%;
    width: 80%;

  }

  .navItem {
    margin: 20px 0;
    font-size: 23px;
  }

  .navItem :hover {
    color: var(--main-color);
  }

  .darbuotojaiPos {
    margin-top: -29%;
  }

  img.logout {
    height: unset;
    width: 50px;
  }

  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.passIcon {
    right: 10px;
  }

  .lentele {
    width: unset;
    margin: 20px;
  }

  .mainImgNaujiena {
    width: 100%;
  }

  .passIcon2 {
    position: absolute;
    margin: 20px 0 0 0;
    right: -2%;
    top: 38%;
  }

  .pagrindiniaiMyg {
    flex-direction: column;
    align-items: center;
  }

  .summary {
    flex-direction: column;
  }

  .darb {
    flex-direction: column;
    align-items: left;
    display: flex;
    align-items: center;
    padding: 0 10vw;
  }

  .imagez {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 0 10vw;
    align-items: center;
  }

  .medziaguGrid {
    margin: 0 0 20px 0;
    height: auto;
  }

  .pasirinktiDarbArch {
    justify-content: center;
    display: flex;
  }


  .kablukasLent {
    width: 80%;
  }

  .darbuotojai {
    width: 80%;
  }

  .selectDarbasCont {
    padding: 0;
  }

  .iskvietimasLaikas {
    height: unset !important;
  }

  .selectas .fullFlex.padding .row {
    flex-direction: column;
    align-items: center;
  }

  .selectas .fullFlex.padding {
    flex-direction: column;
    align-items: center;
  }

  .panaudotosMedz {
    padding: 0 10vw;
  }

  .mobileHidden {
    display: none !important;
  }

  .mobileVisible {
    display: block;
    width: 100%;
  }

  .panaudotosMedz .mobile.grid.row .column-mobile-grid div:first-of-type {
    padding: 0 20px 0 0;
  }

  .column-mobile-grid>div:last-of-type {
    text-align: right;
  }

  .panaudotos {
    height: auto;
    max-height: 60vh;
  }

  .kablukoMedziagos {
    border: 1px dotted #db643e;
    padding: 11px;
  }

  .kablukoVidujeMedziagos>div {
    flex-wrap: wrap;
    margin: 20px 0;
    border-bottom: 1px solid var(--main-color);
    width: 100%;
  }

  .widthProc {
    width: 100%;
  }
  
  .widthProc2 {
    width: 100%;
  }

  .widthProc3 {
    width: 100%;
  }

  .kabMarginLine{
    margin-bottom: 25px!important;
  }

  .newsBtnCont{
    padding: 0px 10px 30px 20px;
  }
  .kablBtnCont{
    padding: 80px 10px 30px 20px;
  }

  .lentele.naujienos{
    max-height: unset;
  }
}