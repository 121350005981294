.archyvas {
  width: 1211px;
}

/* .Truksta {
  background-color: var(--main-color);
  border-radius: 5px;
} */

.Truksta .MuiOutlinedInput-input {
  color: red !important;
}

.Perteklius .MuiOutlinedInput-input {
  color: red !important;
}

/* .Perteklius
  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
  border: 2px solid var(--main-color) !important;
  border-color: var(--main-color) !important;
} */

.MuiFormControl-root.MuiTextField-root.Perteklius.css-1u3bzj6-MuiFormControl-root-MuiTextField-root
  label {
  background-color: var(--main-background);
}

.userPicker .selected {
  background-color: rgba(159, 112, 57, 0.542);
}

.hasEvent button {
  overflow: hidden;
}

.hasEvent button {
  background: var(--main-background) !important;
  color: var(--main-color) !important;
}

.VOelW > div div.column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.VOelW > div > div:first-of-type {
  display: none;
}

.VOelW svg {
  display: none;
}

.medziaguRow {
  display: table-row;
  /* grid-auto-flow: column; */
  /* grid-template-columns: 220px 100px 100px 1fr; */
  margin: 20px 0;
}

.medziaguRow .MuiOutlinedInput-input {
  height: 5px;
  width: 50px;
  padding: 10px;
  text-align: center;
}

.medziaguRow p {
  margin: 0 5px;
  line-height: 1.8;
}

.medziaguGrid {
  /* overflow: auto; */
  margin: 10px 0;
  /* height: 80vh; */
}

.kalendorius.lentele {
  width: 100vw;
  /* overflow: auto; */
  margin-top: 2%;
  max-width: 900px;
}

@media screen and (max-width: 767px) {
  .medziaguRow {
    /* grid-auto-flow: column; */
    -ms-grid-columns: 220px 10px 100px 10px 100px;
    grid-template-columns: 220px 100px 100px;
    gap: 10px;
  }

  .modalaz .info {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 10vw;
  }
  .selectedDarbas.row.fullFlex {
    flex-direction: column;
    align-items: center;
  }
  /* .row.pointer {
    justify-content: center;
  } */
  .selectedDarbas.fullFlex.padding {
    padding: 20px 10vw;
  }
}

li.row.panaudotosMedziagos {
  display: flex;
  grid-auto-flow: flex;
  grid-template-columns: 100% 1fr 1fr 1fr;
  gap: 30px;
  border-bottom: 2px solid var(--main-color);
  flex-wrap: nowrap;
}

li.row.panaudotosMedziagos p:first-of-type {
  flex: 100%;
}

.ReactModal__Content.ReactModal__Content--after-open.modal3:focus-visible {
  outline: none;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999;
  /* -webkit-transform: translate(0vh, 0vw);
  -ms-transform: translate(0vh, 0vw);
  transform: translate(0vh, 0vw); */
  background: var(--main-color);
  height: 100vh;
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.selectDarbasCont {
  max-height: 50vh;
  overflow: auto;
}

.column.calendar.event {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.overAll {
  overflow: auto;
  max-height: 90vh;
}

.kablukoMedziagos {
  max-height: 38vh;
  display: block;
  overflow: auto;
}

.modal {
  /* width: 80vw; */
  max-width: 1000px;
}

.panaudotos {
  height: 20vh;
  overflow: auto;
}

/* ///mobile//// */
@media screen and (max-width: 550px) {
  .medziaguRow {
    display: flex;
  }

  .medziaguRow {
    flex-wrap: wrap;
  }
}

.kablukoVidujeMedziagos {
  max-height: 40vh;
  overflow: auto;
  margin: 10px 0 25px 0;
}

.selectas {
  position: absolute;
  top: 100%;
  /* width: 100%; */
  background: var(--main-background);
  z-index: 99;
  width: 100%;
  padding: 0;
  margin: 0px;
}

.selectCont {
  position: relative;
}

.secondary {
  border: 1px solid var(--main-color) !important;
  background-color: var(--main-background) !important;
  color: var(--main-color);
  font-size: 15px;
  font-weight: bold;
}

.secondary:hover {
  color: var(--main-background) !important;
  background-color: var(--main-color) !important;
  border: 1px solid var(--main-color) !important;
}

/* grid mobile */
@media screen and (max-width: 1000px) {
  .mobile.grid.row {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    margin: 50px 0;
    cursor: pointer;
  }

  .mobile.grid.row > div {
    flex: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--main-color);
    padding: 5px 0;
    /* flex-wrap: wrap; */
  }

  .mobile.grid.row img {
    object-fit: scale-down;
    width: 50px !important;
    height: 30px;
  }
}
.kalendorius.lentele {
  color: white;
}

/* .medziaguRow p:nth-of-type(1) {
  width: 620px;
}
.medziaguRow p:nth-of-type(2) {
  width: 80px;
}
.medziaguRow p:nth-of-type(3) {
  width: 100px;
}
.medziaguRow p:nth-of-type(4) {
  width: 60px;
}
.medziaguRow p:nth-of-type(5) {
  width: 60px;
} */

.mobileVisible {
  display: none;
}
@media screen and (max-width: 767px) {
  .medziagu {
    padding: 0 10vw !important;
    /* width: 90vw; */
  }

  .row.pridetiMedz {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
  }

  .mobileColumn {
    flex-direction: column;
  }
  .Pavadinimas-mobile-grid {
    width: 100%;
  }
}
.pavadinimai {
  display: contents;
}
.mobile.grid.element > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0px;
  float: left;
}
.kablukoMedziagosMobile .column-mobile-grid > div:last-of-type {
  text-align: left;
}
.mobile.grid.element .Kiekis-mobile-grid.column-mobile-grid,
.mobile.grid.element .vienetai-mobile-grid.column-mobile-grid {
  margin: 5px 20px 0 0;
}
.mobile.grid.element > div .bold {
  margin-right: 10px;
}

.pswSuAkyte svg {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  margin: 0;
}

.pswSuAkyte {
  position: relative;
}

.pswSuAkyte > div {
  width: 100% !important;
}

.modal {
  padding: 40px !important;
  max-width: 80vw;
  width: 900px;
}
.modalBtnRow button:nth-of-type(n + 2) {
  margin-left: 10px;
}

.modalTekstai.naujienos {
  display: flex;
  flex-direction: column;
}
.modalTekstai.naujienos > * {
  flex: 1;
}
.flyingOne {
  position: sticky;
  top: 30px;
  width: 200px;
  z-index: 10;
  left: 100%;
  display: flex;
}
div {
  position: relative;
}
.flyingOne .closeBtn {
  /*! position: fixed; */
  top: -40px;
}
