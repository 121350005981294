@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");

:root {
  --main-background: #f0eee7;
  --main-backgroundDark: #e8e5dc;
  --main-color: #db643e;
  --main-choose: #f4b050;
  --main-inactive: grey;
  --main-white: #f0eee7;
  --place-holder-clr: grey;
  --main-nepriskirtas: rgb(158, 6, 6);
  --main-priskirtas: #1b792a;
  --main-apmoketa: rgb(33, 104, 15);
  font-family: Roboto !important;

  /* Gaps */
  --gap-md: 1.25rem;
}

.homeLoginLent {
  width: 100vw;
  height: 85vh;
  justify-content: center;
  display: flex;
  align-items: center;
}

.width100 {
  width: 100% !important;
}

.noMarginBot {
  margin-bottom: unset !important;
}

.column {
  display: flex;
  flex-direction: column;
}

.Nepriskirtas.MuiDataGrid-cell > div,
.Nepriskirtas {
  color: var(--main-nepriskirtas);
}

.Atliktas.Apmokėtas.MuiDataGrid-cell > div,
.Atliktas.Apmokėtas {
  color: var(--main-apmoketa);
}

.Priskirtas.MuiDataGrid-cell > div,
.Priskirtas {
  color: var(--main-priskirtas);
}

.Priskirtas.Nepatvirtintas.MuiDataGrid-cell > div,
.Priskirtas.Nepatvirtintas {
  color: var(--main-nepriskirtas);
}

.Atliktas.Apmokėtas {
  color: var(--main-apmoketa);
}

body {
  background: var(--main-color);
  font-family: Roboto;
}

/* h2 {
  color: var(--main-white);
  font-style: normal;
  font-weight: 500;
  font-size: 29px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.0041em;
} */

p {
  margin: 5px 0;
}

ol {
  margin: unset;
  padding-left: 30px;
}

.maxWidth50 {
  max-width: 50%;
}

.margin5 {
  margin: 5px !important;
}

.marginV10 {
  margin: 10px 0 !important;
}

.marginV5 {
  margin: 5px 0 !important;
}

.marginH10{
  margin: 0 10px!important;
}

.navBar {
  position: static;
  top: 0;
  width: 100%;
  background-color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navLogo {
  margin: 20px;
  height: 60px;
}

.navLogo img {
  -o-object-fit: scale-down;
  object-fit: scale-down;
  height: 100%;
}

.navContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: -webkit-fill-available;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  max-width: 900px;
}

.navItem {
  margin: 10px;
  color: white;
  text-decoration: none;
}

#root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  /* justify-content: center; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 100vw;
  min-height: 100vh;
  flex-direction: column;
}

.lentele {
  background-color: var(--main-background);
  padding: 30px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.lentele h2 {
  color: black;
  text-align: left;
}

form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 10px 0;
}

label.checkbox {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

input[type="submit"] {
  margin: 20px;
}

.btn-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin: 20px 0;
}

.container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.containerNews {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* //naujienos */
.naujiena {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 90%;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  /* background: antiquewhite; */
  margin: 20px 20px 10px 10px;
  cursor: pointer;
}

.lentele.naujienos {
  padding: 10px 0;
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: auto;
  max-height: 70vh;
  overflow: auto;
}

.naujiena img {
  height: 70px;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  text-align: center;
}

.naujiena > * {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
}

/* //modal */

.manyImg {
  align-items: baseline;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.manyImg > div > div {
  margin: auto 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  position: relative;
  justify-content: center;
}

.manyImg > div > div {
  margin: auto 0;
}

.padidinti img {
  object-fit: cover;
  width: 100%;
}

.padidinti {
  max-width: 100px;
  overflow: hidden;
  height: 137px;
}

.manyImg > div {
  /* margin: 10px 10px 10px 0; */
  display: flex;
  flex-direction: column;
  min-width: 100px;
}

/* .manyImg > div:hover::before {
  content: "x";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  display: flex;

  background-color: #f0f8ff9e;
  align-items: center;
  justify-content: center;
  font-size: 57px;
} */

.modalTexts label {
  -webkit-box-align: unset;
  -ms-flex-align: unset;
  align-items: unset;
}

.modalTexts textarea {
  min-height: 60px;
}

.archyvas {
  max-width: 90vw;
  margin: 0 auto;
  background: var(--main-background);
  padding: 30px;
  border-radius: 20px;
}

.dataGrid,
.dataGrid * {
  border: none !important;
  /* overflow: unset!important; */
}

.primary {
  background-color: #dc643e !important;
  color: var(--main-background);
  font-size: 15px;
  font-weight: bold;
}

.btn {
  padding: 10px 20px !important;
  border-radius: 50px !important;
  width: 100%;
}

.btn:hover {
  cursor: pointer;
}

.modal.nuotraukos.hidden {
  display: none;
}

.hidden {
  display: none !important;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.spaceAround {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.spaceBetween {
  justify-content: space-between;
}

.center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* .btn.disabled {
  background-color: var(--main-inactive);
} */
.subktegorija {
  padding: 0 10px;
}
