.testModal {
    padding: 0 30px;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    background-color: var(--main-background);
}



.space {
    margin-right: 5px;
}
