.pridti-child,
.rodoma {
  width: 7.63rem;
  height: 7.63rem;
}
.rodoma {
  position: relative;
  background-color: var(--color-darkgray);
  flex-shrink: 0;
}
.pridti-child {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
}
.icon-button11 {
  position: absolute;
  top: 3.06rem;
  left: 3.06rem;
  width: 1.5rem;
  height: 1.5rem;
  overflow: hidden;
}
.iskleista6,
.pridti {
  height: 7.63rem;
  flex-shrink: 0;
}
.pridti {
  position: relative;
  width: 7.63rem;
}
.iskleista6 {
  width: 23.38rem;
  display: flex;
  flex-direction: row;
  padding: 0 0 var(--padding-md);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
