.calendarContainer {
  max-width: 318px;
  /* max-height: 205px; */
  /* border: 1px solid black; */
  border-radius: 30px;
  padding: 10px;
  margin: 10px 0px 0 0;
}

.react-calendar__navigation {
  display: flex;
  margin-bottom: 3px;
  width: 289px;
}

.react-calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-calendar__month-view__days {
  display: flex;
  justify-content: center;
  align-items: center;
}

button.react-calendar__tile.react-calendar__month-view__days__day {
  border: none !important;
  background-color: var(--main-color);
  padding: 13px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  flex: 0 0 12% !important;
  margin: -8px 3px 0 3px;
}

button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  z-index: 99;
}

button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  background-color: whitesmoke;
  color: var(--main-color)!important;
  border-radius: 100%;
}

button.react-calendar__tile.react-calendar__month-view__days__day:hover {
  border: none !important;
  background-color: #ffffff;
  border-radius: 500px;
  padding: z;
  z-index: 99;
}

.span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  color: black;
}

abbr[title] {
  display: none;
}

button.react-calendar__navigation__label {
  border: none !important;
  background-color: var(--main-color);
  padding: 7px;
  color: black;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  border: none !important;
  background-color: var(--main-color);
  padding: 7px;
  color: black;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  border: none !important;
  background-color: var(--main-color);
  padding: 7px;
  color: black;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}
