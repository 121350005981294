.sigContainer {
  width: 80%;
  height: 80%;
  margin: 5px auto;
  background-color: #fff;
}

.sigPad {
  width: 100%;
  height: 100%;
}

.sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
}
